<div class="total-wrapper">
  Total work time: <span [style]="totalWorkTime < 0? 'color: #c10808': 'color: green'">{{ totalWorkTime | transformMS }}</span>
</div>

<div class="list-wrapper">
  <div *ngIf="workDayList; else noWorkDays">
    <div *ngIf="workDayList.length; else noWorkDays">
      <div *ngFor="let workDay of workDayList">
        <ul>
          <li>
            <div class="flex-container" >
              <div class="title" (click)="openDate(workDay.date)" [class.color]="isOpen(workDay.date)">{{ workDay.date}}</div>
              <div class="hours" *ngIf="isOpen(workDay.date)">
                <ng-template #popContent>
                  <div class="form-wrapper">
                    <form [formGroup]="changeTime" (ngSubmit)="onSubmit()" (keydown.enter)="triggerSubmit(workDay)">
                      <label>Expected working time</label>
                      <input placeholder="Enter your expected working time in hours" name="expectedHours" type="number" step="0.5" formControlName="expectedHours">
                    </form>
                    <div class="buttonForm" (click)="close(p1); onSubmit(workDay)" [ngClass]="(changeTime.valid === true)?'valid':'invalid'" >Submit</div>
                    <div class="buttonForm" (click)="close(p1)">Cancel</div>
                  </div>
                </ng-template>
                <div class="expectedHours" [ngbPopover]="popContent"
                     matTooltip="Expected working hours"
                     [autoClose]="'outside'" triggers="manual"
                     placement="bottom"
                     #p1="ngbPopover" (click)="open(p1); setHours(workDay.expectedWorkingHours)">
                  {{ workDay.expectedWorkingHours | transformMS }}
                </div>
              </div>
              <div (click)="openDate(workDay.date)" class="hours" [ngClass]="{'less-hours': workDay.totalTime < workDay.expectedWorkingHours}"  matTooltip="Total work time of the day"
                   [matTooltipPosition]="toolTipPosition">{{ workDay.totalTime | transformMS }}</div>
            </div>
            <div *ngIf="isOpen(workDay.date)">
              <div class="flex-container workDay" *ngFor="let times of workDay.workPeriodList">
                <div class="row1" (click)="goToDetails(times.id)">
                  <div class="column"> {{ times.startTime | date: 'shortTime' }} - {{ times.endTime | date: 'shortTime' }}</div>
                  <div class="column">Work time: {{ times.workTime | transformMS: true }}</div>
                  <div class="column">Break time: {{ times.breakTime }}m</div>
                </div>
                <div (click)="delete(workDay.date, times.id)" class="delete"><fa-icon class="icon-del" [icon]="faTrash"></fa-icon></div>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </div>
  </div>
  <ng-template #noWorkDays>
    <h3>No Time in your list!</h3>
  </ng-template>


  <div class="play" *ngIf="!timeStarted; else stop"  (click)="startTime()"><fa-icon class="playIcon" [icon]="faPlay"></fa-icon></div>
  <ng-template #stop>
    <div class="stop" (click)="stopTime()" ><fa-icon class="playIcon" [icon]="faStop"></fa-icon></div>
  </ng-template>

</div>
