import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {TimeTrackerService} from '../time-tracker.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {WorkPeriod} from '../work-period.interface';

@Component({
  selector: 'app-add-or-edit',
  templateUrl: './add-or-edit.component.html',
  styleUrls: ['./add-or-edit.component.scss']
})
export class AddOrEditComponent implements OnInit {
  @Input() addTime: boolean;
  workPeriod: WorkPeriod = undefined;

  myValidator: ValidatorFn = (fg: FormGroup) => {
    const startTime = fg.get('startTime').value;
    const endTime = fg.get('endTime').value;

    return (startTime != null && endTime != null) && (endTime) > (startTime)
      ? null
      : { range: true };
  };

  workForm = this.formBuilder.group({
      date: new FormControl(new Date()),
      startTime: new FormControl(new Date(new Date().getTime() - (1000 * 60 * 60 * 2))),
      endTime: new FormControl(new Date()),
      breakTime: new FormControl('')
    },{ validators: this.myValidator }
  );


  constructor(private timeTrackerService: TimeTrackerService, private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const route = this.router.url;
    this.addTime = route.includes('add');
    if(!this.addTime) {
      this.getTime(this.route.snapshot.params['id']);
    }
  }


  triggerSubmit() {
    if (!this.workForm) {
      console.warn('triggerForm not assigned a value');
    } else {
      if (this.workForm.valid) {
        this.onSubmit();
      }
    }
  }

  onSubmit() {
    if (this.workForm.valid) {
      const date: Date = new Date(this.workForm.controls['date'].value);
      const startTime: Date = new Date(this.workForm.controls['startTime'].value);
      const endTime: Date = new Date(this.workForm.controls['endTime'].value);
      let breakMinutes = parseInt(this.workForm.controls['breakTime'].value);
      if(!breakMinutes){ breakMinutes = 0 };
      if (this.addTime){
        this.timeTrackerService.addTime(startTime, endTime, date, breakMinutes);
      } else {
        this.timeTrackerService.updateTime(this.route.snapshot.params['id'], startTime, endTime, date, breakMinutes);
      }
      this.goBack();
    }
  }

  private getTime(id: string) {
    this.timeTrackerService.getSpecialWorkTime(id).subscribe(workPeriod => {
      this.workPeriod = workPeriod;
      const startTime = moment(workPeriod.startTime.toString()).format();
      const endTime = moment(workPeriod.endTime.toString()).format();
      const breakTime = workPeriod.breakTime === 0 ? 0 : workPeriod.breakTime;
      this.workForm = this.formBuilder.group({
          date: new FormControl(new Date(this.workPeriod.date)),
          startTime: new FormControl(new Date(startTime)),
          endTime: new FormControl(new Date(endTime)),
          breakTime: new FormControl(breakTime)
        }, {validators: this.myValidator}
      );
    });
  }

  cancel() {
    this.goBack();
  }

  goBack() {
    this.router.navigate(['']);
  }
}
