import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TimeTrackerComponent} from './time-tracker/time-tracker.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AboutComponent} from './about/about.component';
import {SettingsComponent} from './settings/settings.component';
import {AddOrEditComponent} from './time-tracker/add-or-edit/add-or-edit.component';


const routes: Routes = [
  { path: '', component: TimeTrackerComponent, data: {title: 'Time Tracker'}},
  { path: 'month', component: TimeTrackerComponent, data: {title: 'Time Tracker - This month'}},
  { path: 'add', component: AddOrEditComponent, data: {title: 'Time Tracker - Add time'}},
  { path: ':id/edit', component: AddOrEditComponent, data: {title: 'Time Tracker - Edit time'}},
  { path: 'about', component: AboutComponent, data: {title: 'Time Tracker - About'}},
  { path: 'settings', component: SettingsComponent, data: {title: 'Time Tracker - Settings'}},
  { path: '404', component: NotFoundComponent, data: {title: 'Time Tracker - Page not found'}},
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
