<div class="form-wrapper" >
  <form [formGroup]="workForm" (ngSubmit)="onSubmit()"
        (keydown.enter)="triggerSubmit()">
    <label>Date</label>
    <input [owlDateTimeTrigger]="date" [owlDateTime]="date" placeholder="Enter your date" name="date" type="text" formControlName="date" required>
    <owl-date-time [pickerType]="'calendar'" #date></owl-date-time>

    <label>Start Time</label>
    <input [owlDateTimeTrigger]="startTime" [owlDateTime]="startTime" placeholder="Enter your start time" name="startTime" type="text" formControlName="startTime" required>
    <owl-date-time [pickerType]="'timer'" #startTime></owl-date-time>

    <label>End Time</label>
    <input [owlDateTimeTrigger]="endTime" [owlDateTime]="endTime" placeholder="Enter your start time" name="endTime" type="text" formControlName="endTime" required>
    <owl-date-time [pickerType]="'timer'" #endTime></owl-date-time>

    <label>Break Time</label>
    <input placeholder="Enter your break time in minutes" name="breakTime" type="number" formControlName="breakTime">

  </form>
  <div *ngIf="addTime; else editTime">
    <div (click)="onSubmit()" class="button" [ngClass]="(workForm.valid === true)?'valid':'invalid'">Add Time</div>
  </div>
  <ng-template #editTime>
    <div (click)="onSubmit()" class="button" [ngClass]="(workForm.valid === true)?'valid':'invalid'">Edit Time</div>
  </ng-template>
  <div class="button" (click)="cancel()">Cancel</div>
</div>
