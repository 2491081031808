<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <div class="container-fluid">

  <a class="navbar-brand" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
    <img src="../assets/logo.svg" alt="logo" style="width:40px;">
  </a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" routerLink="/add" routerLinkActive="active">Add Time</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/settings" routerLinkActive="active">Week</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/month" routerLinkActive="active">Month</a>
        </li>
      </ul>
      <ul class="navbar-nav settings">
        <li class="nav-item">
          <a class="nav-link" routerLink="/about" routerLinkActive="active">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/settings" routerLinkActive="active">Settings</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="content">
  <router-outlet></router-outlet>
</div>
