import { NgModule } from '@angular/core';
import {AppComponent} from '../app.component';
import {TimeTrackerComponent} from './time-tracker.component';
import {TransformMSPipe} from './transform-ms.pipe';
import { BrowserModule, Title } from '@angular/platform-browser';
import {AppRoutingModule} from '../app-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AddOrEditComponent} from './add-or-edit/add-or-edit.component';

@NgModule({
    declarations: [
      TimeTrackerComponent,
      TransformMSPipe,
      AddOrEditComponent
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      FontAwesomeModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      BrowserAnimationsModule,
      NgbModule,
      MatTooltipModule,
    ],
    providers: [Title],
    bootstrap: [AppComponent]
  })

export class TimeTrackerModule { }
