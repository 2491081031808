import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {TimeTrackerService} from "./time-tracker.service";
import { faTrashAlt, faPlayCircle} from "@fortawesome/free-regular-svg-icons";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";

import { WorkDay } from "./work-day.interface";
import {FormBuilder, FormControl} from '@angular/forms';


@Component({
  selector: 'app-time-tracker',
  templateUrl: './time-tracker.component.html',
  styleUrls: ['./time-tracker.component.scss']
})
export class TimeTrackerComponent implements OnInit {

  faTrash = faTrashAlt;
  faPlay = faPlay;
  faStop = faStop;

  toolTipPosition = 'below';
  timeStarted = false;
  workDayList: WorkDay[];
  totalWorkTime: number;
  detailViewDate;

  constructor(private router: Router, private timeTrackerService: TimeTrackerService,  private formBuilder: FormBuilder) { }

  changeTime = this.formBuilder.group({
      expectedHours: new FormControl('')
    });

  close(pop:any): void {
    pop.close();
  }

  open(pop:any): void {
    pop.open();
  }

  triggerSubmit(workDay: WorkDay): void {
    if (!this.changeTime) {
      console.warn('triggerForm not assigned a value');
    } else {
      if (this.changeTime.valid) {
        this.onSubmit(workDay);
      }
    }
  }

  onSubmit(workDay: WorkDay): void {
    if (this.changeTime.valid) {
      const expectedHours = this.changeTime.controls['expectedHours'].value;
      this.timeTrackerService.updateExpectedWorkingHours(workDay.date, expectedHours * 3600000);
      this.getWorkingTimeList();
      this.getTotalTime();
    }
  }


  ngOnInit(): void {
    this.timeTrackerService.setFilter(this.router.url); 
    this.getWorkingTimeList();
    this.getTotalTime();
    if(this.timeTrackerService.getTimeFromLocalStorage()) this.timeStarted = true;
  }

  addTime() {
    this.router.navigate(['add']);
  }

  getWorkingTimeList() {
    this.timeTrackerService.getWorkTimes().subscribe(workingTimes => this.workDayList = workingTimes);
  }

  goToDetails(id: string) {
    const route = id + '/edit';
    this.router.navigate([route]);
  }

  delete(date: string, id: string) {
    this.timeTrackerService.deleteWorkTime(date, id);
    this.getWorkingTimeList();
    this.getTotalTime();
  }

  getTotalTime() {
    this.timeTrackerService.getTotalTime().subscribe(value => this.totalWorkTime = value);
  }

  openDate(date: string) {
    this.isOpen(date) ? this.detailViewDate = '': this.detailViewDate = date;
  }

  isOpen(date: string) {
    return date === this.detailViewDate;
  }

  startTime() {
    this.timeStarted = true;
    this.timeTrackerService.setTime();
  }

  checkStartEnd(start: Date, end: Date):boolean {
    return (start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth() && start.getDate() === end.getDate());
  }

  stopTime() {
    this.timeStarted = false;
    const start = new Date(this.timeTrackerService.getTimeFromLocalStorage());
    const now = new Date();
    const correctTime = this.checkStartEnd(start, now);
    if (correctTime){
      this.timeTrackerService.addTime(start, now, now, 0);
      this.timeTrackerService.clearTime();
      this.getWorkingTimeList();
      this.getTotalTime();
    } else {
      alert('Wrong time. The start and end time had different days, which is not allowed so far!')
    }
  }

  setHours(expectedWorkingHours: number) {
    this.changeTime.controls['expectedHours'].setValue(expectedWorkingHours / 3600000);
  }
}
