<div class="content-wrapper">

  <div class="about-me">
    <div class="image-wrapper">
      <img src="./assets/brasov.jpg" class="my-image" alt="aboutMe">
    </div>
    <div class="header-wrapper">
      <h1>Niklas Hauschel</h1>
    </div>
    <div  class="text-wrapper">
    

      <p id="contact">In any cases feel free to contact me:</p>
      <div class="link-wrapper">
        <a class="link1" target="_blank" href="https://www.linkedin.com/in/niklas-hauschel/"><div><img class="link1IMG" src="assets/linkedin.svg" alt="LinkedIn"></div></a>
        <a class="link2" target="_blank" href="https://github.com/niklashauschel/"><img class="link2IMG" src="assets/001-github.svg" alt="Github"></a>
      </div>

      <p>The date and time picker is provided by <a href="https://danielykpan.github.io/date-time-picker/" class="link" target="_blank">Ng Date Time Picker</a> </p>
      <p>Icons made by <a href="https://www.flaticon.com/authors/freepik" target="_blank"  class="link" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" target="_blank" class="link" title="Flaticon">www.flaticon.com</a></p>
      <p>Popover created by <a href="https://ng-bootstrap.github.io/#/home" target="_blank"  class="link" title="ngBootstrap">ng-bootstrap</a></p>

    </div>
  </div>
</div>





