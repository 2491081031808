import { Injectable } from '@angular/core';
import { WorkPeriod } from './work-period.interface';
import { Observable, of } from 'rxjs';
import { WorkDay } from './work-day.interface';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackerService {

  workDayList: WorkDay [] = [];
  totalTime = 0;
  WORK_DAY_DEFAULT_TIME_IN_MS = 28800000;
  filter = '';

  constructor() { }

  // tested
  getTotalTime(): Observable<number> {
    this.getFromStorage();
    this.calcTotalTime();
    return of(this.totalTime);
  }

  // tested
  addWorkTime(id: string, startTime: Date, endTime: Date, date: Date, workTime: number, breakTime: number): void {
    const workPeriod = {id, startTime: startTime.toString(), endTime: endTime.toString(), date, breakTime, workTime} as WorkPeriod;
    const workDay = this.getWorkDay(this.getDateString(date));
    if (workDay) {
      workDay.workPeriodList.push(workPeriod);
      workDay.totalTime = this.calcTotalTimeDay(workDay);
    } else {
      const workPeriodList = [workPeriod];
      if (this.workDayList) {
        this.workDayList.push({date: this.getDateString(date), workPeriodList, totalTime: workTime, expectedWorkingHours: this.WORK_DAY_DEFAULT_TIME_IN_MS});
      } else {
        this.workDayList = [{date: this.getDateString(date), workPeriodList, totalTime: workTime, expectedWorkingHours: this.WORK_DAY_DEFAULT_TIME_IN_MS}];
      }
    }
    this.sortWorkDayList();
    this.calcTotalTime();
    this.pushToStorage();
  }

  // tested
  sortWorkDayList(): void {
    this.workDayList.sort((a, b) => moment(b.workPeriodList[0].date).unix() - moment(a.workPeriodList[0].date).unix());
  }

  // tested
  pushToStorage(): void {
    localStorage.setItem('time', JSON.stringify(this.workDayList));
  }

  // tested
  getFromStorage(): void {
    const retrievedObject = localStorage.getItem('time');
    const allData = JSON.parse(retrievedObject);
    if (this.filter === '/month') {
      this.workDayList = this.filterObjectMonth(allData)
    } else {
      this.workDayList = allData;
    }
  }

  // tested
  filterObjectMonth(workdays: Array<WorkDay>): Array<WorkDay> {
    const result = workdays.filter((workday: WorkDay) => {
      const date = moment(workday.date, 'MMMM Do YYYY').toDate();
      return date.getMonth() === new Date().getMonth()
    })
    return result;
  }

  setFilter(filterUrl: string) {
    this.filter = filterUrl;
  }

  // tested
  calcTotalTimeDay(workDay: WorkDay) {
    let result = 0;

    workDay.workPeriodList.forEach(function(value) {
      result += value.workTime;
    });
    return result;
  }

  // tested
  calcTotalTime(): void {
    let result = 0;
    let workHours = 0;

    if (this.workDayList) {
      this.workDayList.forEach(function(value) {
        result += value.totalTime;
        workHours += value.expectedWorkingHours;
      });
      this.totalTime = result - workHours;
    } else {
      this.totalTime = 0;
    }
  }

  addTime(startTime: Date, endTime: Date, date: Date, breakMinutes: number): void {
    const result = this.calcWorkTime(startTime, endTime, date, breakMinutes);
    const uniqID =  (new Date()).getTime();
    this.addWorkTime(uniqID.toString(), result.newStart, result.newEnd, date, result.workTime, breakMinutes);
  }

  // tested
  calcWorkTime(startTime: Date, endTime: Date, date: Date, breakMinutes: number) {
    const startDate: Date = new Date (date.getFullYear(), date.getMonth(), date.getDay() , startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
    const endDate: Date = new Date (date.getFullYear(), date.getMonth(), date.getDay(), endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
    return { workTime: endDate.getTime() - startDate.getTime() - (breakMinutes * 1000 * 60), newStart: startDate, newEnd: endDate };
  }

  updateTime(id: string, startTime: Date, endTime: Date, date: Date, breakMinutes: number) {
    const result = this.calcWorkTime(startTime, endTime, date, breakMinutes);
    const workPeriod = this.getWorkPeriod(id);
    const workDay = this.getWorkDay(this.getDateString(date));
    if (workDay) {
      workPeriod.startTime = result.newStart.toString();
      workPeriod.endTime = result.newEnd.toString();
      workPeriod.date = date;
      workPeriod.breakTime = breakMinutes;
      workPeriod.workTime = result.workTime;
      workDay.totalTime = this.calcTotalTimeDay(workDay);
    } else {
      const workDay = this.getDateListItemById(id);
      this.addWorkTime(id, startTime, endTime, date, result.workTime, breakMinutes);
      this.deleteWorkTime(workDay.date, id);
    }
    this.calcTotalTime();
    this.pushToStorage();
    this.getFromStorage();
  }

  getWorkDay(date: String): WorkDay {
    if (this.workDayList) {
      return this.workDayList.find(element => element.date === date);
    }
    return undefined;
  }

  getDateListItemById(id): WorkDay {
    if (this.workDayList) {
      let i, j;
      for (i = 0; i < this.workDayList.length; i++) {
        for (j = 0; j < this.workDayList[i].workPeriodList.length; j++) {
          if (this.workDayList[i].workPeriodList[j].id === id){
            return this.workDayList[i];
          }
        }
      }
    }
    return undefined;
  }

  getWorkTimes(): Observable<WorkDay[]> {
    this.getFromStorage();
    return of(this.workDayList);
  }

  getSpecialWorkTime(id: string): Observable<WorkPeriod> {
    this.getFromStorage();
    return of(this.getWorkPeriod(id));
  }

  // tested
  getDateString(date: Date): string {
    return moment(date).format('MMMM Do YYYY'); // August 20th 2015
  }

  getWorkPeriod(id: string): WorkPeriod {
    if (!this.workDayList) return;
    for (let i = 0; i < this.workDayList.length; i++) {
      const workPeriod = this.workDayList[i].workPeriodList.find(element => element.id === id) as WorkPeriod;
      if (workPeriod) return workPeriod;
    }
    return;
  }

  deleteWorkTime(date: string, id: string): void {
    const workDay = this.getWorkDay(date);
    if (workDay.workPeriodList.length === 1) {
     this.workDayList.splice(this.workDayList.indexOf(workDay), 1);
    } else {
      const workPeriod = workDay.workPeriodList.find(element => element.id === id);
      workDay.workPeriodList.splice(workDay.workPeriodList.indexOf(workPeriod), 1);
      workDay.totalTime = this.calcTotalTimeDay(workDay);
    }
    this.calcTotalTime();
    this.pushToStorage();
    this.getFromStorage();
  }

  setTime(): void {
   localStorage.setItem('runTime', JSON.stringify(new Date()));
  }

  getTime(): Observable<string> {
    const time = this.getTimeFromLocalStorage();
    return of(time);
  }

  getTimeFromLocalStorage(): string {
    const retrievedObject = localStorage.getItem('runTime');
    return JSON.parse(retrievedObject);
  }

  clearTime(): void {
    localStorage.removeItem('runTime');
  }

  updateExpectedWorkingHours(date: any, expectedTime: any) {
    const workDay = this.getWorkDay(date);
    if (workDay) {
      workDay.expectedWorkingHours = expectedTime;
    }
    this.calcTotalTime();
    this.pushToStorage();
    this.getFromStorage();
  }
}
