import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformMS'
})
export class TransformMSPipe implements PipeTransform {

  transform(value: number, time: boolean = false, ...args: unknown[]): unknown {
    // console.log("Test");
    if (value) {
      let neg = '';
      if(value < 0) neg = '-';
      let ms = Math.abs(value);
      let sec = Math.floor(ms / 1000);
      ms = ms / 60 / 1000;
      let minutes: any = Math.floor(ms % 60);
      ms = ms / 60;
      let hours: any = Math.floor(ms % 24);
      let days: any = Math.floor(ms/24);

      if ((minutes as number) < 10) {
        (minutes as string) = `0${minutes}`;
      }
      if ((hours as number) < 10) {
        (hours as string) = `0${hours}`;
      }
      if (time) {
        if(sec < 60 && sec > 0) {
          return `${sec}s`
        }
      }
      if ((days as number) === 0) {
        return `${neg}${hours}h ${minutes}m`;
      }

      if ((days as number) < 10) {
        (days as string) = `0${days}`;
        return `${neg}${days}d ${hours}h ${minutes}m`;
      }
    } else {
      return '-';
    }
  }

}
